
///////////////////////////////////////////////////////////////////////////////////////////////////////
/**
 * Login.jsx
 * 로그인 페이지
 */
///////////////////////////////////////////////////////////////////////////////////////////////////////

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '/react/test/src/css/Login.css';
import '/react/test/src/css/Modal.css';
import Modal from 'react-modal';
import CryptoJS from 'crypto-js';
import prod from '../config/prod';  // 운영 서버 설정
import { setUserAuth } from '../auth'; // 사용자 인증 정보 저장

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const Login = () => {
    /* ------------------------ State ------------------------ */
    const [userId, setUserId]       = useState(''); // 아이디
    const [password, setPassword]   = useState(''); // 패스워드
    const [error, setError]         = useState(''); // 오류 메시지

    const navigate = useNavigate(); // 네비게이트 함수

    // 알림 모달 표시
    const [isModalOpen, setIsModalOpen]     = useState(false);
    const [modalMessage, setModalMessage]   = useState('');

    


    /* ------------------------ Handler ------------------------ */
    // 모달 표시 함수
    const showModal = (message) => {
        setModalMessage(message);
        setIsModalOpen(true);
    };



    /* ------------------------ API ------------------------ */
    // API를 호출하여 사용자 인증 처리
    const authenticateUser = async (userId, password) => {
        try {
            const URL = `${prod.SERVER}/api/user/login` // 로그인 API 호출
            
            // 로그인 계정 정보
            const loginInfo = {
                "userId"    : userId,
                "password"  : CryptoJS.SHA256(password).toString()
            };

            const response = await fetch(URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginInfo),
            });

            if (!response.ok) {
                throw new Error('네트워크 응답이 올바르지 않습니다.');
            }

            const data = await response.json();
            console.log("[Login.jsx] 로그인 응답 ::: ", data);

            if (data.success) {
                // 사용자 인증 정보 저장
                const userData = {
                  userId: data.userId,
                  userNo: data.userNo,
                  userName: data.name,
                  url: data.url,
                  hospitalId: data.hospitalId
                };
                
                const authResult = setUserAuth(userData);
                if (!authResult) {
                  return { success: false, message: '로그인 처리 중 오류가 발생했습니다.' };
                }
                
                return { success: true, data: userData };
              } else {
                return { success: false, message: data.message || '로그인에 실패했습니다.' };
              }
        } catch (error) {
            console.error('[Login.jsx] 로그인 실패:', error);
            return { success: false, message: '서버 오류가 발생했습니다' };
        }
    };

    

    /* ------------------------ Event ------------------------ */
    // 로그인 이벤트
    const handleLogin = async (event) => {
        event.preventDefault(); // 이벤트 기본 동작 방지
        setError(''); // 오류 메시지 초기화

        try {
            const result = await authenticateUser(userId, password); // 인증 처리
            console.log("[Login.jsx] 로그인 결과 ::: ", result);

            // 별도 URL 접근 설정이 안되어 있으면 오류 메시지 표시
            /*
            if (result.data.url === null) {
                console.log("별도 URL 접근 설정이 안되어 있습니다.")
                showModal('현재 접근 권한이 없습니다. 관리자에게 문의하세요.');
                return;
            }
            */

            // 로그인 성공 시 별도 URL 접근
            if (result.success) {
                //navigate(result.data.url || '/main', { replace: true });
                setTimeout(() => {
                    //navigate('/main', { replace: true });
                    window.location.href = '/main';
                    console.log("[Login.jsx] 로그인 성공");
                }, 0);
            } else {
                showModal(result.message);
            }
        } catch (error) {
            console.error('[Login.jsx] 로그인 실패:', error);
            showModal('서버 오류가 발생했습니다');
        }

        
    };



    /* ------------------------ Render ------------------------ */
    return (
        <div className="login-container">
            <form onSubmit={handleLogin}>
                <h2>POPBIO</h2>
                <div className="form-group">
                    <label>아이디</label>
                    <input
                        type="text"
                        value={userId}
                        onChange={(e) => setUserId(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>비밀번호</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="login-button">로그인</button>
            </form>

            <Modal 
                isOpen={isModalOpen} 
                onRequestClose={() => setIsModalOpen(false)} 
                className="modal-content"
            >
                <h2 className="modal-title">알림</h2>
                <p className="modal-message">{modalMessage}</p>
                <button className="modal-button" onClick={() => setIsModalOpen(false)}>닫기</button>
            </Modal>
        </div>
    );
};

export default Login;