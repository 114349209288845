import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { format } from 'date-fns';

import '../css/AiccFrame.css'; // 컴포넌트 스타일 적용
import prod from '../config/prod';  // 운영 서버 설정
import {aiccApi} from '../utils/aiccApi'; // 서버 통신 함수 

import { FaEdit, FaSave, FaTimes } from 'react-icons/fa'; // React Icons에서 편집 아이콘 import

import patientListIcon from '../assets/patient_list.png';
import chatBubbleIcon from '../assets/chat_bubble.png';
import clinicalNotesIcon from '../assets/clinical_notes.png';
import defaultProfileIcon from '../assets/default_profile.svg';

import SearchBox from './SearchBoxAicc';
////////////////////////////////////////////////////////////////////////////////////

// 테스트용 환자목록
const patientList = [
  { id: 1, name: '홍길동', birthDate: '1990-01-01', phone: '010-9876-2815', gender: 'male'},
  { id: 2, name: '김철수', birthDate: '1985-03-15', phone: '010-7628-2815', gender: 'male' }, 
  { id: 3, name: '이영희', birthDate: '1995-07-20', phone: '010-3333-3333', gender: 'female' },
  { id: 4, name: '박민교', birthDate: '1988-11-30', phone: '010-4444-4444', gender: 'male' },
  { id: 5, name: '최지원', birthDate: '1992-05-12', phone: '010-5555-5555', gender: 'female' },
];

// 서버 주소
//const SERVER = 'https://popbio.ai' // 운영
//const SERVER = 'http://118.67.131.227:8090' // 개발
//const SERVER = 'http://localhost:8090' // 개발_Local

//const AICC_PROGRAM = 'http://127.0.0.1:9876/mac'
////////////////////////////////////////////////////////////////////////////////////

function AiccFrame() {

  /* ----------------- 상태 변수 관리 ----------------- */
  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  
  const location        = useLocation();
  const webSocket       = useRef(null); // 웹소켓 연결 객체
  const [user, setUser] = useState(null);
  const userNo = useRef(null);
  const macAddress = useRef(null); // 상담원의 IP전화 MAC 주소
  
  const [isRecording, setIsRecording] = useState(false);
  const [searchTerm, setSearchTerm] = useState(''); // 검색어 상태 추가

  
  const [socket, setSocket] = useState(null);
  const [transcription, setTranscription] = useState(null);
  
  const [newDataItem, setNewDataItem] = useState({ content: [] });
  const [isCallActive, setIsCallActive] = useState(false);
  const [summary, setSummary] = useState('');
  const [phoneNumberList2, setPhoneNumberList2] = useState([]);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState(null);
  const [connData, setConnData] = useState(null); // 통화기록 리스트

  // 수신받은 데이터를 저장할 상태
  const [receivedData, setReceivedData] = useState([]);
  const [receivedData_json, setReceivedData_json] = useState('[]');

  const messagesEndRef = useRef(null); // 채팅 메시지 최하단 참조 객체

  
  const [editingIndex, setEditingIndex] = useState(null); // 상담 고객 이름 수정 인덱스
  const [editName, setEditName] = useState(''); // 상담 고객 이름 수정


  /* ----------------- 이벤트 함수 ----------------- */

  // 이름 수정 시작 핸들러
  const handleEditName = (index, currentName) => {
    setEditingIndex(index);
    setEditName(currentName || '');
  };

  // 이름 저장 핸들러
  const handleSaveName = async (index, phoneNumber) => {
    try {
      // API 호출하여 이름 저장
      const response = await fetch(`${prod.SERVER}/api/aicc-wss/saveClientName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userNo: userNo.current,
          phoneNumber: phoneNumber,
          clientName: editName
        })
      });

      if (!response.ok) {
        throw new Error('이름 저장 실패');
      }
      

      // phoneNumberList2 업데이트
      setConnData(prevList => {
        return prevList.map((item, i) => {
          if (i === index) {
            return { ...item, clientName: editName };
          }
          return item;
        });
      });

      setEditingIndex(null);
    } catch (error) {
      console.error('이름 저장 중 오류:', error);
      alert('이름 저장에 실패했습니다.');
    }
  };

  // 환자 추가 버튼 클릭 시 호출 이벤트
  const handleAddPatient = () => {
    console.log('환자 추가 이벤트 실행');
  };


  // 환자 검색 버튼 클릭 시 호출 이벤트
  const handleSearch = () => {
    console.log('환자 검색 이벤트 실행');

    // 팝업 상태 토글
    if (showSearchPopup) {
      setShowSearchPopup(false);
      setSearchTerm('');
      setSearchResults([]);
    } else {
      setShowSearchPopup(true);
      // TODO: 실제 검색 로직 구현
      setSearchResults(patientList);
    }
  };

  
  // 상담고객 선택 이벤트
  const handlePatientSelect = (patient) => {
    console.log('[AiccFrame] selectedPatient ::: ', selectedPatient);
    //console.log('[AiccFrame] connData ::: ', connData);
    //console.log('[AiccFrame] patient ::: ', patient);
    
    try {
      // 선택된 환자 상태 업데이트
      setSelectedPatient(patient);

      // 검색 팝업 닫기
      setShowSearchPopup(false);

       // connData에서 선택된 환자 찾기
      const selectedIndex = connData.findIndex(item => 
        item.clientName === patient.clientName && 
        item.caller === patient.phone
      );
      
      // 선택된 환자를 맨 앞으로 이동
      if (selectedIndex !== -1) {
        const newList = [connData[selectedIndex], ...connData.filter(item => item.caller !== patient.phone)];
        setConnData(newList);
      }

      // 선택된 환자 업데이트
      setSelectedPhoneNumber(patient.phone);

      // 선택된 환자의 상담내역 조회
      aiccApi.fetchMessageListToJSON(prod.SERVER, userNo.current, patient.phone).catch(error => {
        console.log('메시지 리스트 조회 JSON 에러:', error);
        setReceivedData_json([]); 
      });
    } catch (error) {
      console.error('환자 선택 중 오류 발생:', error);
    }
  };

  
  // 환자 검색어 처리 함수
  const handleSearchChange = (e) => {
    console.log('검색어 변경 이벤트 실행');
  };


  // 상담고객 번호클릭 이벤트
  const handlePhoneNumberClick = (phoneNumber, index) => {
    //console.log(`[AiccFrame] handlePhoneNumberClick index: ${index} , phoneNumber: ${phoneNumber}`);

    // 선택된 전화번호 업데이트
    setSelectedPhoneNumber(phoneNumber);
    
    // 메시지 리스트 조회
    aiccApi.fetchMessageListToJSON(prod.SERVER, userNo.current, phoneNumber).catch(error => {
      console.log('메시지 리스트 조회 JSON 에러:', error);
      setReceivedData_json([]); 
    });
  };



  /* ------------------------ useEffect ------------------------ */
  useEffect(() => {
    // 크롬 브라우저 감지
    const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    
    // 크롬 브라우저 [영어 -> 한국어] 번역 방지
    if (isChrome) {
      // 메타 태그 생성
      const noTranslateMeta = document.createElement('meta');
      noTranslateMeta.name = 'google';
      noTranslateMeta.content = 'notranslate';
      
      // head에 메타 태그 추가
      document.head.appendChild(noTranslateMeta);
      
      // html 태그에 클래스 추가
      document.documentElement.classList.add('notranslate');
    }
  }, []);


  /* ----------------- useEffect ----------------- */
  useEffect(() => {
    // 로그인 ID 조회
    /*
    if (location.state) {
      setUser(location.state.user);
      userNo.current = location.state.user.userNo;
      console.log('[AiccFrame] 로그인 ID ::: ', userNo.current);
    }
    */

    // 사용자 정보 로드
    const userSession = sessionStorage.getItem('user');
    if (userSession) {
      const userData = JSON.parse(userSession);
      userNo.current = userData.userNo;
      setUser(userData);
      //console.log('[AicaFrame.jsx] 로그인 ID ::: ', userNo.current);
    }
  }, []);


  /* ------------------------ useEffect ------------------------ */
  // AICC 프로그램에서 MAC 주소 조회
  const initializeMacAddress = async () => {
    try {
      const response = await fetch(prod.AICC_PROGRAM, { 
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        mode: 'cors',
        credentials: 'omit'
      });

      if (!response.ok) {
        throw new Error('HTTP error! status ::: ', response.status);
      }

      const data = await response.json();
      macAddress.current = data.mac;
    } catch (error) {
      console.log('상담원의 IP전화 MAC 주소 조회 에러:', error);
      macAddress = null; // 개발용은 CORS 에러로 인해 임시 주소 설정
      // TODO: 받아올 MAC주소가 없을 때의 처리 방안 필요
    }

    //console.log('[AiccFrame] 상담원 MAC 주소 :::', macAddress.current);
  }


  /* ------------------------ useEffect ------------------------ */
  // 초기 설정
  useEffect(() => {
    const initializeComponent = async () => {
      await initializeMacAddress(); // 상담원의 IP전화 MAC 주소 조회
      
      //let macAddress = null; // 상담원의 IP전화 MAC 주소
      //console.log('[AiccFrame] macAddress :::', macAddress.current);

      // 웹소켓 연결
      const socket = io(`${prod.SERVER}/aicc-wss`, {
        query: { 
          userNo: userNo.current, 
          mac: macAddress.current
        },
        reconnectionDelay: 1000, // 재연결 대기 시간
        reconnectionDelayMax: 5000, // 최대 재연결 대기 시간
        reconnectionAttempts: 10 // 재연결 시도 횟수
      });

      // 웹소켓 연결 에러
      socket.on('connect_error', (error) => {
        console.error('웹소켓 연결 에러:', error);
      });
  

      // 생성된 소켓을 상태로 설정
      setSocket(socket);
  

      // 웹소켓 연결 완료 시 실행
      socket.on('connection', async (id, connData) => {
        //console.log('[AiccFrame] 웹소켓 연결 완료 ::: (id : ', id, ') // ', connData.length, '건 조회됨.');
        //console.log('[AiccFrame] connData :::', connData.length);
        
        // 통화기록 리스트 저장
        setConnData(connData);
        
        // connData에서 전화번호 추출
        // TODO item 에 담긴 seq_nos 도 전화번호와 같이 관리가 되어야 한다.
        const newPhoneNumbers = connData.map(item => item.caller);

        // connData에서 전화번호와 이름 추출
        const newPhoneNumbers2 = connData.map(item => ({
          number: item.caller,
          clientName: item.clientName
        }));

        // phoneNumberList2 업데이트
        setPhoneNumberList2(prevList => {
          const updatedList = Array.from(new Set(newPhoneNumbers2.map(JSON.stringify))).map(JSON.parse);
          return updatedList;
        });

        // 첫번째 번호에 매칭되는 통화 데이터 전부 조회 2 (JSON 방식)
        try {
          await aiccApi.fetchMessageListToJSON(prod.SERVER, userNo.current, newPhoneNumbers[0]);
        } catch (error) {
          console.log('에러: ', error);
          setReceivedData([]); // 빈 화면 표시
        }

  
        // 첫 번째 전화번호를 선택된 번호로 설정 (옵션)
        if (newPhoneNumbers.length > 0 && !selectedPhoneNumber) {
          setSelectedPhoneNumber(newPhoneNumbers[0]);
        }
      });
  
  
      // 서버로부터 전화 신호(ACK, BYE)를 받을 때 실행
      socket.on('sceq', async (data) => {
        const sceq = data.sceq;
        const phoneNumber = data.from;

        // 최신 phoneNumberList 상태를 가져옵니다.
        if (sceq === 'ACK') {
          setIsCallActive(true);  // 통화 활성화
          setSummary(null);       // 요약 초기화

          setConnData(prevList => {
            const isExistingNumber = prevList.find(item => item.caller === phoneNumber);
            
            
            if (!isExistingNumber) {
              // 신규번호 수신 처리
              console.log('[AiccFrame] socket connection ::: 신규번호 수신 [', phoneNumber, ']');
              const newList = [{ caller: phoneNumber, clientName: null }, ...prevList];
              
              //setReceivedData([]); // 빈 화면 표시
              setReceivedData_json('[]'); // 빈 화면 표시
              
              return newList;
            } else {
              // 기존 번호인 경우
              const existingItem = prevList.find(item => item.caller === phoneNumber);
              const updatedItem = { ...existingItem, clientName: existingItem.clientName }; // clientName 업데이트
              const newList = [updatedItem, ...prevList.filter(item => item.caller !== phoneNumber)];
              
              
              // 메시지 리스트 조회
              aiccApi.fetchMessageListToJSON(prod.SERVER, userNo.current, phoneNumber).catch(error => {
                console.log('메시지 리스트 조회 JSON 에러:', error);
                setReceivedData_json('[]');
              });
              
              //return updatedList;
              return newList;
            }
          });

          setSelectedPhoneNumber(phoneNumber); // 통화 번호 선택
        }
        // 통화 종료
        else if (sceq === 'BYE') {
          // connData 0 Index 에 번호,seqNos 추가
          setConnData(prevList => {
            const updatedList = [...prevList];
            updatedList[0] = { ...updatedList[0], caller: phoneNumber, seq_nos: data.call_id };
            return updatedList;
          });

          // 통화 내역 저장 및 요약 처리
          setTranscription((prevList) => {
            if (prevList === null) {
              setIsCallActive(false);
              return null;
            }

            try {
              const textBuffer = prevList.map(item => item.text).filter(Boolean);
              const summaryText = textBuffer.join('\n');
              
              // 통화 기록 저장
              saveCallLogs(userNo.current, data.call_id, data.from, prevList, summaryText);
              
              return null;
            } catch (error) {
              console.log('에러:', error);
              setIsCallActive(false);
            }
          });
    
          // transcription 초기화
          setTranscription(null);
          setIsCallActive(false);

          // TODO connData 새로고침
        }
      }); 
  
  
      // 서버로부터 통화 데이터를 받을 때마다 실행
      socket.on('transcribe', (data) => {
        const status = data.result_code;
        
        // 무음 처리
        if ( status === 201 ) {
          return;
        }

        // 새로운 통화데이터를 transcription에 저장
        setTranscription(prevList => {
          // prevList가 null 또는 undefined인 경우를 대비해 빈 배열을 기본값으로 사용
          const currentList = prevList || [];
          return [...currentList, data];
        });

        setNewDataItem(prevItem => {
          // prevItem.content가 배열인지 확인하고, 아니면 새 배열 생성
          const prevContent = Array.isArray(prevItem.content) ? prevItem.content : [];
          return { ...prevItem, content: [...prevContent, data] };
        });
        

        // 수신된 통화데이터를 상태에 추가
        setReceivedData_json(prevData => {
          console.log('setReceivedData_json 리랜더링'); // 디버깅을 위한 로그
          
          // prevData가 문자열인 경우 JSON 파싱 시도
          let parsedData;
          if (typeof prevData === 'string') {
            try {
              parsedData = JSON.parse(prevData);
            } catch (error) {
              console.error('JSON 파싱 오류:', error);
              parsedData = [];
            }
          } else if (Array.isArray(prevData)) {
            parsedData = prevData;
          } else {
            console.warn('receivedData_json의 형식이 예상과 다릅니다. 빈 배열로 초기화합니다.');
            parsedData = [];
          }

          // 현재 활성 세션 확인
          const currentSession = parsedData.length > 0 ? parsedData[parsedData.length - 1] : null;

          if (currentSession && currentSession.seqNo === newDataItem.seqNo) {
            // 현재 세션이 존재하고 seqNo가 일치하면 해당 세션에 새 데이터 추가
            const updatedData = [...parsedData];
            const lastIndex = updatedData.length - 1;

            updatedData[lastIndex] = {
              ...updatedData[lastIndex],
              content: [...(updatedData[lastIndex].content || []), data]
            };

            return JSON.stringify(updatedData);
          } else {
            // 새로운 세션 시작 또는 첫 데이터인 경우
            return JSON.stringify([
              ...parsedData,
              {
                seqNo: newDataItem.seqNo,
                recodingNo: newDataItem.recodingNo,
                content: [data]
              }
            ]);
          }
        });
        

        //updatePhoneNumberList(data.phone_number);
        
        // 데이터 수신 시, 자동으로 해당 전화번호 선택 (옵션)
        //setSelectedPhoneNumber(data.phone_number);
      });
  
  
      // 서버로부터 통화 기록 리스트 화면 출력
      socket.on('messageList', (list) => {
        //console.log('messageList 응답 :::',list);
        setReceivedData(list);
      });


      // 서버로부터 통화 기록 리스트 화면 출력 (JSON)
      socket.on('messageList_json2', (list) => {
        //console.log('[AiccFrame] messageList_json2 ::: 통화 기록 리스트 조회');
        setReceivedData_json(list);
      });

      /*
      const txt = `1. 환자 김철수는 최근 3일 동안 지속적인 두통을 호소함.
        2. 두통이 아침에 주로 심해진다고 말함.
        3. 상담원이 두통의 원인을 파악하기 위해 병원 예약을 권장함.
        4. 김철수는 약을 복용 중이지만 증상이 개선되지 않는다고 설명함.
        5. 김철수의 다음 예약일은 10월 15일로 확인됨.
        6. 김철수는 예약된 날에 병원에 방문할 수 있는지 상담원에게 문의함.
        7. 상담원은 김철수에게 예약 확인을 위해 병원으로 다시 전화할 것을 조언함.`;
      setSummary(txt);
      */

      // 서버로부터 통화 요약 (Summary) 결과 받기
      socket.on('summary', (result) => {
        console.log('[AiccFrame] 상담 요약 결과 ::: ', result.summary);

        // 배열이면 줄바꿈으로 연결, 아니면 그대로 사용
        /*
        const summaryText = Array.isArray(result.summary) 
        ? result.summary.join('\n')  // 배열이면 줄바꿈으로 연결
        : result.summary;            // 아니면 그대로 사용
        */

        /*
        // summary 데이터 처리
        const summaryText = Array.isArray(result.summary) 
        ? result.summary.map(item => {
            // 객체인 경우 text 속성을 사용하거나 적절한 속성값을 추출
            if (typeof item === 'object' && item !== null) {
              return item.text || item.content || JSON.stringify(item);
            }
            return item;
          }).join('\n')
        : typeof result.summary === 'string' 
          ? result.summary
          : JSON.stringify(result.summary);
        */

        const summaryText = result.summary;
        
        setSummary(summaryText);

        //updateReceivedData(receivedData_json, result.summary);
        // receivedData_json 업데이트
        setReceivedData_json(prevData => {
          try {
            const parsedData = typeof prevData === 'string' ? JSON.parse(prevData) : prevData;
            
            if (parsedData.length > 0) {
              // 마지막 세션에 요약 추가
              const updatedData = [...parsedData];
              const lastSession = updatedData[updatedData.length - 1];
              
              // 요약 메시지 추가
              lastSession.content.push({
                type: 2, // 요약 타입
                text: summaryText
              });
              
              return JSON.stringify(updatedData);
            }
            return prevData;
          } catch (error) {
            console.error('[AiccFrame] 요약 데이터 업데이트 중 오류:', error);
            return prevData;
          }
        });

        setIsCallActive(false); // 통화 비활성화
      });
    };


    initializeComponent();
    

    // 컴포넌트가 언마운트될 때 소켓 연결 해제
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);  // 빈 배열로 설정하여 컴포넌트가 마운트될 때만 실행



  /* ------------------------ useEffect ------------------------ */
  useEffect(() => {
    // 스크롤 최하단 이동처리
    if (messagesEndRef.current) {
      // behavior : auto , smooth
      messagesEndRef.current.scrollIntoView({ behavior: 'auto' });
    }
  }, [transcription, receivedData_json]);



  /* ------------------------ useEffect ------------------------ */
  useEffect(() => {
    const handleClickOutside = (event) => {
      
      // 검색 팝업과 검색 버튼 요소 찾기
      const searchPopup = document.querySelector('.search-popup');
      //const searchButton = document.querySelector('.search-button');
      //const searchInput = document.querySelector('.search-input');

      //console.log('[AiccFrame] handleClickOutside 동작 ::: showSearchPopup ::: ', showSearchPopup);
      
      // 검색 팝업이 열려있고, 클릭된 요소가 검색 관련 요소가 아닌 경우에만 팝업 닫기
      if (showSearchPopup) {
        const isClickInside = searchPopup.contains(event.target);
        console.log('[AiccFrame] handleClickOutside ::: isClickInside ::: ', isClickInside);
        if (!isClickInside) {
          setShowSearchPopup(false);
          setSearchTerm('');
          setSearchResults([]);
        }
      }
      
    };
  
    // 이벤트 리스너 등록
    document.addEventListener('mousedown', handleClickOutside);
  
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearchPopup]); // showSearchPopup이 변경될 때마다 useEffect 실행



  // 날짜 변환
  const formatDate = (dateString) => {
    if (!dateString) return '';

    const formatText = 'yyyy년 MM월 dd일 HH시 mm분';
    const date = format(new Date(dateString), formatText);
    return date;
  };

  // 통화 요약 줄바꿈 처리
  const formatText = (text) => {
    // text가 null이거나 undefined일 경우 빈 문자열 반환
    if (!text) return '';

    return text.split('\n').map((line, index, array) => 
      index === array.length - 1 ? line : <>
        {line}
        <br />
      </>
    );
  };



  // 통화 기록 저장
  const saveCallLogs = async (userNo, callId, phoneNumber, transcriptions, summaryText) => {
  
    const URL = `${prod.SERVER}/api/aicc-wss/save`
    try {
      console.log('[AiccFrame] save() <callId ::: ', callId, '>');

      const exampleText = `
      `;

      const jsonData = {
        "userNo"          : userNo,
        "callId"          : callId,
        "phoneNumber"     : phoneNumber,
        "transcriptions"  : transcriptions,
        "summaryText"     : summaryText
      };

      const response = await fetch(URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(jsonData),
      });

      if (!response.ok) {
        throw new Error('[AiccFrame] saveCallLogs() 에러 ::: 네트워크 응답이 올바르지 않습니다.');
      }
      
      console.log('[AiccFrame] saveCallLogs() 결과 ::: ', response.data);
    } catch (error) {
      console.error('[AiccFrame] saveCallLogs() 에러 :::', error);
    }
  };



  /* ----------------- 화면 렌더링 ----------------- */
  return (
    <div className="aica-container">
      
      {/* 상단 검색 영역 */}
      <div className="top-header">
        <SearchBox 
          user={user} 
          onPatientSelect={handlePatientSelect}
          showSearchPopup={showSearchPopup}
          setShowSearchPopup={setShowSearchPopup}
        />

        <div className="profile">
          <img src={defaultProfileIcon} alt="profile" className="profile-image" />
          <span className="profile-status"></span>
        </div>
      </div>


      



      {/* 메인 영역 */}
      <div className="aica-main">
        {/* --------- 왼쪽 섹션 - 상담 고객 --------- */}
        <div className="left-section">
          {/* 고객 목록 */}
          <div className="patient-list-box">
            
            <div className="box-header">
              <div className="icon-circle">
                <img 
                  src={patientListIcon}
                  alt="상담고객"
                  className="icon-mark"
                />
              </div>
              <h3>상담 고객</h3>
              <div className="spacer" />
              {/* <button className="add-button" onClick={handleAddPatient}>+</button> */}
            </div>

            <div className="list-content">
              <div className="patient-list">
                {connData && connData.map((item, index) => (
                  <div 
                    key={index}
                    className={`patient-item ${selectedPhoneNumber === item.caller ? 'selected' : ''}`}
                    onClick={() => !editingIndex && handlePhoneNumberClick(item.caller, index)}
                  >
                    {editingIndex === index ? (
                      <div className="edit-mode">
                        <input
                          value={editName}
                          maxLength={20}
                          onChange={(e) => setEditName(e.target.value || null)}
                          className="name-input"
                          placeholder="고객 이름 입력"
                          autoFocus
                        />
                        <div className="edit-buttons">
                          <FaSave 
                            onClick={() => handleSaveName(index, item.caller)}
                            className="save-icon"
                            title="저장"
                          />
                          <FaTimes 
                            onClick={() => setEditingIndex(null)}
                            className="cancel-icon"
                            title="취소"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="patient-name">
                          {item.clientName || ''}
                        </span>
                        <span className="patient-phone">{item.caller}</span>
                        <FaEdit 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditName(index, item.clientName);
                          }}
                          className="edit-icon"
                          title="이름 수정"
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>

          </div>
        </div>




        {/* --------- 오른쪽 섹션 - 상담 영역 --------- */}
        <div className="right-section">
          
          {/* 상담 */}
          <div className="chat-content-box">
            <div className="box-header">
              <div className="icon-circle">
                <img 
                  src={clinicalNotesIcon}
                  alt="상담"
                  className="icon-mark"
                />
              </div>
              <h3>상담</h3>
            </div>

            <div className="chat-content">
              <ul className="chat-messages">
                {receivedData_json && typeof receivedData_json === 'string' ? (
                  JSON.parse(receivedData_json).flatMap((session) => [
                    <li key={`session-start-${session.id}`} className="session-start-time">
                      {formatDate(session.startDt)}
                    </li>,
                    session.content.map((message, messageIndex) => (
                      message.text && (
                        <li
                          key={`${session.seqNo}-${messageIndex}`}
                          className={`chat-message ${
                            message.type === 1 ? 'right' : message.type === 2 ? 'summary' : 'left'
                          }`}
                        >
                          {message.type === 2 ? formatText(message.text) : message.text}
                        </li>
                      )
                    ))
                  ])
                ) : (
                  <li>메시지가 없습니다.</li>
                )}
                <div ref={messagesEndRef} /> {/* 스크롤 타겟 요소 추가 */}
              </ul>
            </div>
          </div>


          {/* 상담 요약 */}
          <div className="chat-summary-box">
            <div className="box-header">
              <div className="icon-circle">
                  <img 
                    src={clinicalNotesIcon}
                    alt="상담요약"
                    className="icon-mark"
                  />
                </div>
              <h3>상담 요약</h3>
            </div>

            <div className="summary-content">
              
              {/*<ul>
                {summary ? (
                  <li>{formatText(summary)}</li>
                ) : (
                  <li>상담 요약이 없습니다.</li>
                )}
              </ul>
              */}
              <textarea 
                className="summary-textarea" 
                value={summary} // null 체크 추가
                readOnly
                //onChange={(e) => setSummary(e.target.value)}
              />
            </div>
          </div>

        </div>
      </div>



      
      
    </div>
  );
};

export default AiccFrame;
////////////////////////////////////////////////////////////////////////////////////