import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useDebounce } from 'use-debounce';

import '../css/AicaFrame.css'; // 컴포넌트 스타일 적용
import styles from '../css/AicaFrame.css';
import prod from '../config/prod';  // 운영 서버 설정
import { common } from '../utils/common';

import patientListIcon from '../assets/patient_list.png';
import chatBubbleIcon from '../assets/chat_bubble.png';
import clinicalNotesIcon from '../assets/clinical_notes.png';
import defaultProfileIcon from '../assets/default_profile.svg';

import { FaEdit, FaSave, FaTimes } from 'react-icons/fa';

import SearchBox from './SearchBox';
////////////////////////////////////////////////////////////////////////////////////

//fjeiowajfojeaofjeoa
//fjeioawjfoiejaofijeo

function AicaFrame() {
  /* ----------------- 상태 변수 관리 ----------------- */
  const location        = useLocation();
  const [user, setUser] = useState(null);
  const userNo          = useRef(null);
  const [urlType, setUrlType] = useState(''); // url 타입 상태 추가

  const webSocket       = useRef(null); // 웹소켓 연결 객체
  const [socket, setSocket] = useState(null);

  const [isRecording, setIsRecording] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [summaryText, setSummaryText] = useState('');
  
  const mediaRecorder = useRef(null);
  const audioContext  = useRef(null);
  const processor     = useRef(null);

  const animationFrameId = useRef(null); // 대화중인지 추척하는 함수의 에니메이션 설정 ID

  const [showSearchPopup, setShowSearchPopup] = useState(false); // 검색 팝업 상태
  const [selectedPatient, setSelectedPatient] = useState(null); // 선택된 환자
  

  const [startDt, setStartDt] = useState(null); // 상담 시작 시간
  const [endDt, setEndDt]     = useState(null); // 상담 종료 시간

  const [patientList, setPatientList]         = useState([]); // 환자 목록
  const [medicalHistory, setMedicalHistory]   = useState([]); // 이전 진료 내역
  const [selectedHistory, setSelectedHistory] = useState(null); // 선택된 진료 내역

  const [editingIndex, setEditingIndex] = useState(null); // 편집 중인 환자 인덱스
  const [editName, setEditName]         = useState(''); // 편집 중인 환자 이름
  
  // 상담종료 후 요약 수신 받을 동안 로딩 상태 관리
  const [isLoading, setIsLoading] = useState(false); 

  // 오디오 버퍼 관리를 위한 Ref
  const entireAudioChunks = useRef([]); // 전체 음성버퍼 저장  
  const audioChunks       = useRef([]); // 음성버퍼 저장
  
  const activeBuffer    = useRef([]); // 현재 채우고 있는 버퍼
  const pendingBuffers  = useRef([]); // 전송 대기 중인 버퍼들

  // 오디오 버퍼 관련 상태 추가
  //const [lastSendTime, setLastSendTime] = useState(Date.now());
  const lastSendTime = useRef(Date.now());
  
  const SAMPLE_RATE   = 16000;             // 16kHz
  const SEND_INTERVAL = 5000;             // 5초
  const CHUNK_RATE    = 128;              // 버퍼 크기 (ms)
  const BUFFER_SIZE   = SAMPLE_RATE * 5;  // 40000 샘플 (5초 분량)
  const SAFETY_BUFFER_MULTIPLIER  = 2.5;  // 안전 버퍼 승수 (100% 여유공간)

  const MAX_BUFFER_SIZE           = BUFFER_SIZE * SAFETY_BUFFER_MULTIPLIER; // 80000 샘플
  const MAX_PENDING_BUFFERS       = 2;    // 최대 대기 버퍼 수
  const FORCE_SEND_THRESHOLD      = 0.95; // 강제 전송 임계값 상향 (90%)


  /* ----------------- 이벤트 함수 ----------------- */

  // 환자 추가 버튼 클릭 시 호출 이벤트
  const handleAddPatient = () => {
    console.log('환자 추가');
  };


  // 검색 된 환자 선택
  const handlePatientSelect = (selectedPatient) => {
    console.log('[AicaFrame.jsx] 환자 선택 처리 ::: ', selectedPatient);

    // 선택된 환자 상태 업데이트
    setSelectedPatient(selectedPatient);

    // 검색 팝업 닫기
    setShowSearchPopup(false);

    // 환자 목록 재정렬
    setPatientList(prevList => {
      // 선택된 환자와 동일한 환자가 목록에 있는지 확인
      const existingIndex = prevList.findIndex(
        p => p.patientName === selectedPatient.patientName
      );

      if (existingIndex === -1) {
        // 목록에 없으면 맨 앞에 추가
        return [selectedPatient, ...prevList];
      } else {
        // 목록에 있으면 해당 환자를 맨 앞으로 이동
        const newList = [...prevList];
        newList.splice(existingIndex, 1); // 기존 위치에서 제거
        return [selectedPatient, ...newList]; // 맨 앞에 추가
      }
    });

    // 진료 내역 조회
    fetchMedicalHistory(userNo.current, selectedPatient.patientName);
  };


  // 이름 수정 시작 핸들러
  const handleEditName = (index, currentName) => {
    if (isRecording) return; // 상담 중이면 이벤트 무시
    
    setEditingIndex(index);
    setEditName(currentName || '');
  };


  // 이름 저장 핸들러
  const handleSaveName = async (index, currentName) => {
    try {
      // API 호출하여 이름 저장
      const response = await fetch(`${prod.SERVER}/api/aica-wss/saveClientName`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userNo: userNo.current,
          currentName: currentName || '',
          changeName: editName
        })
      });

      if (!response.ok) {
        throw new Error('이름 저장 실패');
      }

      // patientList 업데이트
      setPatientList(prevList => {
        return prevList.map((item, i) => {
          if (i === index) {
            return { ...item, patientName: editName };
          }
          return item;
        });
      });

      // 선택된 환자의 이름이 변경되었다면 selectedPatient도 업데이트
      if (selectedPatient && selectedPatient.patientName === currentName) {
        console.log('[AicaFrame.jsx] 선택된 환자의 이름이 변경되었다면 selectedPatient도 업데이트');
        setSelectedPatient(prev => ({
          ...prev,
          patientName: editName
        }));

        // 진료 내역 재조회
        fetchMedicalHistory(userNo.current, editName);
      }

      setEditingIndex(null);
    } catch (error) {
      console.error('이름 저장 중 오류:', error);
      alert('이름 저장에 실패했습니다.');
    }
  };


  // 웹소켓 연결 종료 함수
  const closeWebSocket = () => {
    if (socket) {
      socket.close();
      console.log('[AicaFrame.jsx] 웹소켓 연결이 종료되었습니다.');
    }

    // 모든 버퍼 초기화
    activeBuffer.current = [];
    pendingBuffers.current = [];
    entireAudioChunks.current = [];
    audioChunks.current = [];
  };


  // 녹음 시작
  const startRecording = () => {
    // 상담 시작 시각 기록
    const startTime   = common.formatDateTime(new Date());  // yyyy-mm-dd HH:mm:ss
    const startTime2  = common.formatDateTime2(new Date()); // yyyymmdd_HHmmss
    setStartDt(startTime);
    console.log('[AicaFrame.jsx] 상담 시작 시각 ::: ', startTime, startTime2);

    // 웹소켓 연결 종료
    closeWebSocket();

    // 상담 내용, 상담요약 초기화
    setTranscriptionText('');
    setSummaryText('');

    // 버퍼 초기화
    activeBuffer.current      = [];
    pendingBuffers.current    = [];
    lastSendTime.current      = Date.now();
    entireAudioChunks.current = [];

    try {
      // 웹소켓 연결
      const socket = io(`${prod.SERVER}/aica-wss`, {
        query: { 
          userNo    : userNo.current,
          startTime : startTime2
         },
        reconnectionDelay: 1000,      // 재연결 대기 시간
        reconnectionDelayMax: 3000,   // 최대 재연결 대기 시간
        reconnectionAttempts: 3      // 재연결 시도 횟수
      });

      // 소켓 상태 검토
      if (!socket) {
        console.error('[AicaFrame.jsx] 소켓이 연결되지 않았습니다.');
        // TODO: 소켓 연결 실패 처리 (팝업 알림 등)
        return;
      }

      // 소켓 객체 저장
      setSocket(socket);


      // 리스너 - 웹소켓 연결 완료 
      socket.on('connection', async () => {
        try {
          // 오디오 컨텍스트 지원 여부 확인
          if (!window.AudioContext || !window.AudioWorklet) {
            console.error('AudioContext 또는 AudioWorklet을 지원하지 않음');
            return;
          }

          console.log('[AicaFrame.jsx] 웹소켓 연결 완료');

          // 마이크 권한 요청
          console.log('[AicaFrame.jsx] 마이크 권한 요청 중...');
          const stream = await navigator.mediaDevices.getUserMedia({ 
            audio: {
              sampleRate: SAMPLE_RATE,    // 원하는 샘플레이트
              channelCount: 1,            // 모노
              echoCancellation: true,     // 에코 제거
              noiseSuppression: true,    // 노이즈 제거
              autoGainControl: true,     // 자동 소음 제거
              latency: 0.01,              // 녹음 지연 시간 최소화
              sampleSize: 16            // 샘플 크기 8비트
            }
          });

          // 마이크 권한 확인
          const micPermission = await navigator.permissions.query({ name: 'microphone' });
          console.log('[AicaFrame.jsx] 마이크 정보 ::: ', micPermission.state);

          if (!stream) {
            console.error('[AicaFrame.jsx] 마이크 권한 요청 실패');
            return;
          }

          // 마이크 정보 확인
          const tracks = stream.getAudioTracks();
          const mic = tracks[0];
          console.log('[AicaFrame.jsx] 마이크 정보 ::: ', mic.getSettings().deviceId);

          // 녹음 객체 생성
          mediaRecorder.current = new MediaRecorder(stream);

          // 오디오 컨텍스트 생성
          audioContext.current = new window.AudioContext({
            sampleRate: SAMPLE_RATE,
          });
          console.log('[AicaFrame.jsx] 녹음 객체 및 오디오 컨텍스트 생성 완료');

          // 오디오 처리 모듈 로드
          await audioContext.current.audioWorklet.addModule("./linear16-processor-home.js");
          //await audioContext.current.audioWorklet.addModule("./linear16-processor-pro.js");

          // 오디오 소스 생성
          const source = audioContext.current.createMediaStreamSource(stream);
          
          // 오디오 처리 모듈 생성
          processor.current = new AudioWorkletNode(audioContext.current, "linear16-processor");
          /*
          processor.current = new AudioWorkletNode(audioContext.current, "linear16-processor", {
            processorOptions: {
              useAdvancedProcessing: true,    // 고급 처리 모드 사용
              sampleRate: SAMPLE_RATE,        // 샘플레이트
              
              // 게인 설정
              applyGain: true,                // 게인 적용
              gainLevel: 1.5,                 // 볼륨 150% 증가
              
              // 노이즈 게이트 설정
              applyNoiseGate: false,          // 노이즈 게이트 적용
              noiseThreshold: 0.01,           // 노이즈 임계값
              
              // 음성 향상 필터 설정
              applyVoiceEnhancement: true,    // 음성 향상 필터 적용
              lowCutFreq: 80,                // 저주파 차단 (80Hz)
              highCutFreq: 7500,              // 고주파 차단 (7.5kHz)

              // 중간 주파수 부스트 설정 (명료도 향상)
              applyMidBoost: false,
              midBoostFreq: 2500,
              midBoostGain: 1.5,
              midBoostQ: 1.0,
              
              // 압축기 설정
              applyCompressor: false,        // 압축기 적용
              threshold: -20,               // 압축 임계값 (dB)
              ratio: 3,                     // 압축 비율
              attack: 0.002,                // 어택 시간 (초)
              release: 0.08,                 // 릴리즈 시간 (초)
              makeupGain: 8,                // 보상 게인 (dB)
              
              // 디에싱 설정
              applyDeEssing: false,          // 디에싱 적용
              essingFreq: 5000,             // 치찰음 주파수 (Hz)
              essingQ: 2.0,                 // Q 팩터
              essingThreshold: -10,         // 디에싱 임계값 (dB)
              essingReduction: 0.5,         // 감소 비율
              
              
              // 초기화 설정 (클릭 노이즈 제거)
              fadeInDuration: 0.05,          // 페이드인 시간 (초)
              silentDuration: 0.05           // 초기 무음 시간 (초)
            }
          }); 
          */
          
          // 녹음 상태 업데이트
          setIsRecording(true);

          // 버퍼 크기 계산 (전송 주기에 따른 적절한 버퍼 크기)
          const BUFFER_LIMIT = Math.ceil((SAMPLE_RATE * SEND_INTERVAL) / 1000); // 샘플레이트 * 전송주기(초)
          




          // 오디오 처리 완료 이벤트 리스너
          processor.current.port.onmessage = (event) => {
            if (!socket) return;
          
            const currentTime = Date.now();
            const timeSinceLastSend = currentTime - lastSendTime.current;
            
            try {
              if (!event.data) { return; }
          
              const chunkData = new Int16Array(event.data);
              
              // 현재 버퍼 크기 계산
              const currentBufferSize = activeBuffer.current.reduce((sum, chunk) => 
                sum + chunk.length, 0);
          
              // 버퍼 상태 모니터링
              const bufferFullnessRatio = currentBufferSize / BUFFER_SIZE;
              
              // 디버깅을 위한 주기적인 버퍼 상태 로깅
              if (currentBufferSize % (SAMPLE_RATE) === 0) {  // 1초마다 로깅
                console.log('[AicaFrame.jsx] 버퍼 상태:', {
                  현재크기: currentBufferSize,
                  버퍼비율: `${(bufferFullnessRatio * 100).toFixed(1)}%`,
                  경과시간: `${(timeSinceLastSend/1000).toFixed(1)}초`
                });
              }
          
              // 새 데이터 추가 (버퍼 크기 체크 먼저)
              if (currentBufferSize + chunkData.length <= MAX_BUFFER_SIZE) {
                activeBuffer.current.push(chunkData);
              } else {
                console.error('[AicaFrame.jsx] 버퍼 오버플로우 발생');
                // 오버플로우 시 현재 버퍼 강제 전송
                if (activeBuffer.current.length > 0) {
                  pendingBuffers.current.push([...activeBuffer.current]);
                  activeBuffer.current = [chunkData]; // 새로운 청크로 시작
                  processAndSendBuffers(currentTime);
                }
              }
          
              // 정기 전송 간격 또는 임계값 체크
              if (timeSinceLastSend >= SEND_INTERVAL || 
                (bufferFullnessRatio >= FORCE_SEND_THRESHOLD && timeSinceLastSend >= SEND_INTERVAL * 0.9)) {
                // 정기 전송이거나, 버퍼가 거의 다 찼고 전송 간격의 90% 이상 지났을 때만 전송
                if (activeBuffer.current.length > 0) {
                  pendingBuffers.current.push([...activeBuffer.current]);
                  activeBuffer.current = [];
                  processAndSendBuffers(currentTime);
                  
                  console.log('[AicaFrame.jsx] 버퍼 전송 완료:', {
                    전송타입: timeSinceLastSend >= SEND_INTERVAL ? '정기전송' : '임계값전송',
                    버퍼크기: currentBufferSize,
                    경과시간: `${(timeSinceLastSend/1000).toFixed(1)}초`
                  });
                }
              }
          
            } catch (error) {
              console.error('[AicaFrame.jsx] 오디오 처리 오류:', error);
              activeBuffer.current = [];
              pendingBuffers.current = [];
            }
          };
          
          // 버퍼 처리 및 전송 함수
          const processAndSendBuffers = (currentTime) => {
            try {
              while (pendingBuffers.current.length > 0) {
                const bufferToSend = pendingBuffers.current.shift();
                const totalSamples = bufferToSend.reduce((sum, chunk) => 
                  sum + chunk.length, 0);
          
                if (totalSamples > 0) {
                  const mergedData = new Int16Array(totalSamples);
                  let offset = 0;
                  
                  bufferToSend.forEach(chunk => {
                    mergedData.set(chunk, offset);
                    offset += chunk.length;
                  });
          
                  // 기존 Base64 인코딩 방식
                  //const base64Data = btoa(String.fromCharCode.apply(null, new Uint8Array(mergedData.buffer)));
                  // 개선된 Base64 인코딩 방식
                  const base64Data = encodeAudioBufferToBase64(mergedData.buffer);
          
                  socket.emit('audio-stream', {
                    userNo: userNo.current,
                    audioData: base64Data
                  });
          
                  console.log('[AicaFrame.jsx] 오디오 데이터 전송:', {
                    시각: new Date(currentTime).toLocaleTimeString(),
                    샘플수: totalSamples,
                    버퍼크기: `${(totalSamples / SAMPLE_RATE).toFixed(2)}초`,
                    대기버퍼수: pendingBuffers.current.length
                  });
                }
              }
          
              lastSendTime.current = currentTime;
          
            } catch (error) {
              console.error('[AicaFrame.jsx] 버퍼 처리 오류:', error);
              pendingBuffers.current = [];
            }
          };






          // 사용자가 말하고있는지를 체크하는 코드
          const analyser = audioContext.current.createAnalyser();
          analyser.fftSize = 256;
          const dataArray = new Uint8Array(analyser.frequencyBinCount);

          source.connect(processor.current);
          processor.current.connect(audioContext.current.destination);

          source.connect(analyser);

          const detectTalking = () => {
              if (!webSocket.current) {
                return;
              }
    
              analyser.getByteFrequencyData(dataArray);

              // 주파수 대역별 분석 (사람 음성 주파수 대역 강화)
              const voiceFreqStart = Math.floor(85 * analyser.frequencyBinCount / SAMPLE_RATE);
              const voiceFreqEnd = Math.floor(3000 * analyser.frequencyBinCount / SAMPLE_RATE);

              let voiceSum = 0;
              for (let i = voiceFreqStart; i < voiceFreqEnd; i++) {
                voiceSum += dataArray[i];
              }
              
              const avgVoiceVolume = voiceSum / (voiceFreqEnd - voiceFreqStart);

              if (avgVoiceVolume > 40) {
                console.log('[AicaFrame.jsx] 대화중');
                // 음성이 감지될 때 추가 처리
              }

              animationFrameId.current = requestAnimationFrame(detectTalking);
          };

          detectTalking();

          // 녹음 종료 이벤트 리스너
          mediaRecorder.current.onstop = () => {
            console.log('[AicaFrame.jsx] 녹음 종료 이벤트 리스너 실행');
            if (processor.current && audioContext.current) {
              stream.getTracks().forEach((track) => track.stop());
              source.disconnect(processor.current);
              processor.current.disconnect(audioContext.current.destination);
            }
          };

          // 녹음 시작
          mediaRecorder.current.start(CHUNK_RATE);
          console.log('[AicaFrame.jsx] 녹음 시작');
        } catch (error) {
          console.error('[AicaFrame.jsx] 웹소켓 연결 오류:', error);
        }
      });


      // 리스너 - 음성 인식 결과 수신 이벤트
      socket.on('transcription', (data) => {
        console.log('받은 메시지:', data);

        // data.text로 접근하도록 수정
        //if (data.status === 200) {
        if (data.text.length > 0 && data.text !== '') {
          setTranscriptionText(prevText => {
          // 이전 텍스트가 있으면 줄바꿈을 추가하고, 없으면 새로운 텍스트만
            return prevText ? `${prevText}\n${data.text}` : data.text;
          });
        }
      });


      // 리스너 - 웹소켓 연결 해제
      socket.onclose = () => {
        console.log('[AicaFrame.jsx] 웹소켓 연결 해제');

        // 리소스 해제
        if (animationFrameId.current) {
          cancelAnimationFrame(animationFrameId.current);
          animationFrameId.current = null;
        }

        if (processor.current) {
          processor.current.disconnect();
          processor.current = null;
        }

        if (audioContext.current) {
          audioContext.current.close();
          audioContext.current = null;
        }

        if (mediaRecorder.current) {
          mediaRecorder.current.stop();
          mediaRecorder.current = null;
        }
      };

      // 마이크 스트림 전송
      //webSocket.emit('audio-stream', stream);

      // 웹소켓 객체 저장
      webSocket.current = socket;
      console.log('[AicaFrame.jsx] 웹소켓 객체 저장 ::: ', webSocket.current);
    } catch (error) {
      console.error('[AicaFrame.jsx] 상담 시작 에러:', error);
    }
  };


  // 녹음 종료
  const stopRecording = async () => {
    console.log('[AicaFrame.jsx] 녹음 종료');

    try {
      setIsLoading(true); // 로딩 시작
      
      // 1. 상담 종료 시각 기록
      const endTime = common.formatDateTime(new Date());
      setEndDt(endTime);
      console.log('[AicaFrame.jsx] 상담 종료 시각 ::: ', endTime);

      // 전체 음성 데이터 처리 (필요한 경우)
      //console.log('[AicaFrame.jsx] 전체 음성 데이터 크기:', entireAudioChunks.current.length);
      
      // 웹소켓 연결 종료
      closeWebSocket();

      // 2. 상담 내용이 없으면 종료
      if (!transcriptionText.trim()) {
        console.log('[AicaFrame.jsx] 상담 내용이 없습니다.');
        return;
      }

      //console.log('[AicaFrame.jsx] 상담 유저 체크 ::: ', userNo.current);

      // 3. 상담 내용 서버로 전송
      const consultationData = {
        userNo: userNo.current, // 로그인 사용자 번호
        patientName: selectedPatient?.patientName, // 환자 이름
        content: transcriptionText, // 상담 내용
        startDt: startDt, // 상담 시작 시각
        endDt: endTime // 상담 종료 시각
      };

      const response = await fetch(`${prod.SERVER}/api/aica-wss/consultation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(consultationData)
      });

      // 4. 요약 내용 받아서 화면에 표시
      const { summary } = await response.json();
      setSummaryText(summary);

      // 5. 환자 목록과 진료 내역 갱신
      await Promise.all([
        fetchPatientList(userNo.current),
        fetchMedicalHistory(userNo.current, selectedPatient?.patientName)
      ]);
      
    } catch (error) {
      console.error('[AicaFrame.jsx] 상담 종료 에러:', error);
    } finally {
      setIsLoading(false); // 로딩 종료
      setStartDt(null);
      setEndDt(null);

      // 녹음 상태 업데이트
      if (isRecording) {
        setIsRecording(false);
        console.log('[AicaFrame.jsx] 녹음 상태 업데이트 ::: ', isRecording);
      }
    }
  };


  // 마이크 권한 요청 함수
  const getAudioMediaStream = async () => {
      try {
        // 마이크 권한 요청
        console.log('[AicaFrame.jsx] 마이크 권한 요청 중...');
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            sampleRate: SAMPLE_RATE,    // 원하는 샘플레이트
            channelCount: 1,            // 모노
            echoCancellation: true,     // 에코 제거
            noiseSuppression: true,    // 노이즈 제거
            autoGainControl: true,     // 자동 소음 제거
          }
        });

        // 마이크 정보 확인
        const tracks = stream.getAudioTracks();
        const track = tracks[0];

        console.log('[AicaFrame.jsx] 마이크 권한 요청 완료 ::: ', track.getSettings().deviceId);

        return stream;
      } catch (error) {
        console.error('[AicaFrame.jsx] 마이크 권한 요청 실패:', error);
        return null;
      }
  };


  // 환자 목록 조회
  const fetchPatientList = async (userNo) => {
    try {
      const response = await fetch(`${prod.SERVER}/api/aica-wss/patientList`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            userNo: userNo
        })
      });

      if (!response.ok) {
        throw new Error('환자 목록 조회 실패');
      }

      const result = await response.json();
      if (result.success) {
        //console.log('[AicaFrame.jsx] 환자 목록 ::: ', result.data);
        setPatientList(result.data);

        // 첫번째 환자의 이전 진료 내역 조회
        fetchMedicalHistory(userNo, result.data[0].patientName);
      } else {
        throw new Error(result.message);
      };

      
    } catch (error) {
      console.error('[AicaFrame.jsx] 환자 목록 조회 실패:', error);
    }
  };


  // 이전 진료 내역 조회
  const fetchMedicalHistory = async (userNo, patientName) => {
    try {
      const response = await fetch(`${prod.SERVER}/api/aica-wss/medicalHistory`, {
        method: 'POST',
        headers: {  
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userNo: userNo,
          patientName: patientName
        })
      });

      if (!response.ok) {
        throw new Error('이전 진료 내역 조회 실패');
      }

      const result = await response.json(); 
      if (result.success) {
        //console.log('[AicaFrame.jsx] 이전 진료 내역 ::: ', result.data);
        setMedicalHistory(result.data);
      } else {
        throw new Error(result.message);
      } 
    } catch (error) {
      console.error('[AicaFrame.jsx] 이전 진료 내역 조회 실패:', error);
    }
  };


  // 환자 선택 시 이전 진료 내역 조회
  const handleMedicalHistory = (patient) => {
    if (isRecording) return; // 상담 중이면 이벤트 무시

    setSelectedPatient(patient);
    fetchMedicalHistory(userNo.current, patient.patientName);
  };


  // 진료내역 선택 핸들러
  const handleHistorySelect = (history) => {
    if (isRecording) return; // 상담 중이면 이벤트 무시

    setSelectedHistory(history);
    setTranscriptionText(history.content);    // 상담내용 textarea에 표시
    setSummaryText(history.summary);          // 상담요약 textarea에 표시
  };



  /* ----------------- useEffect ----------------- */
  useEffect(() => {
    // 사용자 정보 로드
    const userSession = sessionStorage.getItem('user');
    
    if (userSession) {
      const userData = JSON.parse(userSession);
      userNo.current = userData.userNo;
      setUser(userData);
      setUrlType(userData.url);
      console.log('[AicaFrame.jsx] URL ::: ', userData.url);
    }

    // 환자 목록 조회
    fetchPatientList(userNo.current);
  }, []);

  // 텍스트 매핑 함수
  const getDisplayText = (key) => {
    const textMapping = {
      // 상담실
      aica: {
        patientList: '환자 목록',
        patientInfo: '환자 정보',
        medicalHistory: '이전 상담 내역',
        consultation: '상담',
        summary: '상담 내역 요약'
      },
      // 진료실
      aida: {
        patientList: '환자 목록',
        patientInfo: '환자 정보', 
        medicalHistory: '이전 진료 내역',
        consultation: '진료',
        summary: '진료 내역 요약'
      }
    };

    return textMapping[urlType]?.[key] || textMapping.aica[key];
  };



  /* ----------------- useEffect ----------------- */
  useEffect(() => {
    // 상담 내용 스크롤
    const textarea = document.querySelector('.aica-chat-textarea');
    if (textarea) {
      textarea.scrollTo({
        top: textarea.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [transcriptionText]); // transcriptionText가 변경될 때마다 실행


  /*
  useEffect(() => {
    console.log('[AicaFrame.jsx] Recording 상태:', isRecording);
  }, [isRecording]);
  */


  /* ------------------------ useEffect ------------------------ */
  useEffect(() => {
    const handleClickOutside = (event) => {
      
      // 검색 팝업과 검색 버튼 요소 찾기
      const searchPopup = document.querySelector('.search-popup');
      //const searchButton = document.querySelector('.search-button');
      //const searchInput = document.querySelector('.search-input');

      console.log('[AicaFrame] handleClickOutside 동작 ::: showSearchPopup ::: ', showSearchPopup);

      // 검색 팝업이 열려있고, 클릭된 요소가 검색 관련 요소가 아닌 경우에만 팝업 닫기
      if (showSearchPopup) {
        const isClickInside = searchPopup.contains(event.target);
        console.log('[AiccFrame] handleClickOutside ::: isClickInside ::: ', isClickInside);
        if (!isClickInside) {
          setShowSearchPopup(false);
          //setSearchTerm('');
          //setSearchResults([]);
        }
      }
    };
  
    // 이벤트 리스너 등록
    document.addEventListener('mousedown', handleClickOutside);
  
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSearchPopup]); // showSearchPopup이 변경될 때마다 useEffect 실행



  /* ----------------- 화면 렌더링 ----------------- */
  return (
    <div className="aica-container">
      
      {/* 상단 검색 영역 */}
      <div className="top-header">
        <SearchBox 
          user={user} 
          onPatientSelect={handlePatientSelect} 
          showSearchPopup={showSearchPopup} 
          setShowSearchPopup={setShowSearchPopup}
        />
        
        <div className="profile">
          <img src={defaultProfileIcon} alt="profile" className="profile-image" />
          <span className="profile-status"></span>
        </div>
      </div>

      



      {/* 메인 영역 */}
      <div className="aica-main">
        {/* --------- 왼쪽 섹션 - 환자 목록 --------- */}
        <div className="aica-left-section">
          
          {/* 환자 목록 */}
          <div className={`aica-patient-list-box ${isRecording ? 'disabled' : ''}`}>
            
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={patientListIcon}
                  alt="환자목록"
                  className="icon-mark"
                />
              </div>
              <h3>
                {/*환자 목록*/}
                {getDisplayText('patientList')}
              </h3>
              <div className="spacer" />
              {/*<button className="add-button" onClick={handleAddPatient}>+</button>*/}
            </div>

            <div className="aica-list-content">
              <div className="aica-patient-list">
                {patientList && patientList.map((patient, index) => (
                  <div 
                    key={index}
                    className={`aica-patient-item ${selectedPatient && selectedPatient.patientName === patient.patientName ? 'selected' : ''}`}
                    onClick={() => !editingIndex && handleMedicalHistory(patient)}
                  >
                    {editingIndex === index ? (
                      <div className="edit-mode">
                        <input
                          value={editName}
                          maxLength={20}
                          onChange={(e) => setEditName(e.target.value || null)}
                          className="name-input"
                          placeholder="환자 이름 입력"
                          autoFocus
                        />
                        <div className="edit-buttons">
                          <FaSave 
                            onClick={() => handleSaveName(index, patient.patientName)}
                            className="save-icon"
                            title="저장"
                          />
                          <FaTimes 
                            onClick={() => setEditingIndex(null)}
                            className="cancel-icon"
                            title="취소"
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <span className="aica-patient-name">
                          {patient.patientName || ''}
                        </span>
                        <div className="spacer" />
                        <FaEdit 
                          onClick={(e) => {
                            e.stopPropagation();
                            handleEditName(index, patient.patientName);
                          }}
                          className="edit-icon"
                          title="이름 수정"
                        />
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>



        {/* --------- 중앙 섹션 - 환자 정보 --------- */}
        <div className="aica-middle-section">
          {/* 환자 정보 */}
          <div className={`patient-info-box ${isRecording ? 'disabled' : ''}`}>
            
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={chatBubbleIcon}
                  alt="환자정보"
                  className="icon-mark"
                />
              </div>
              <h3>
                {/*환자 정보*/}
                {getDisplayText('patientInfo')}
              </h3>
            </div>

            <div className="info-content">
              <div className="info-row">
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>환자 이름</label>
                  <input 
                    type="text" 
                    value={selectedPatient ? selectedPatient.patientName : ''} 
                    disabled={isRecording}
                    style={{
                      backgroundColor: isRecording ? '#f5f5f5' : 'white',
                      cursor: isRecording ? 'not-allowed' : 'text'
                    }}
                    onChange={(e) => {
                      if (selectedPatient) {
                        setSelectedPatient({
                          ...selectedPatient,
                          patientName: e.target.value
                        });
                      } else {
                        setSelectedPatient({
                          patientName: e.target.value
                        });
                      }
                    }}
                    maxLength={15}
                  />
                </div>
                
                {/* TODO 추후 환자관리 도입 시 사용 예정
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>생년월일</label>
                  <input 
                    type="text" 
                    value={selectedPatient ? selectedPatient.birthDate : ''} 
                    disabled 
                  />
                </div>
                */}
              </div>

              {/* TODO 추후 환자관리 도입 시 사용 예정
              <div className="info-row">
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>연락처</label>
                  <input 
                    type="text" 
                    value={selectedPatient ? selectedPatient.phone : ''} 
                    disabled 
                  />
                </div>
                <div className="info-item">
                  <label style={{ fontWeight: 'bold' }}>성별</label>
                  <div className="gender-options">
                    <label htmlFor="male">남성</label>
                    <input 
                      type="radio" 
                      name="gender" 
                      id="male" 
                      checked={selectedPatient?.gender === 'male'}
                      disabled 
                    />
                    <label htmlFor="female">여성</label>
                    <input 
                      type="radio" 
                      name="gender" 
                      id="female" 
                      checked={selectedPatient?.gender === 'female'}
                      disabled 
                    />
                  </div>
                </div>
              </div>
              */}

              {/* TODO 추후 환자관리 도입 시 사용 예정
              <div className="info-row">
                <div className="info-item full-width">
                  <label style={{ fontWeight: 'bold' }}>상담메모</label>
                  <textarea placeholder="상담 메모를 입력하세요" rows="4"></textarea>
                </div>
              </div>
              */}

              {/* TODO 추후 환자관리 도입 시 사용 예정
              <div className="info-row">
                <button className="save-button">저장</button>
              </div>
              */}

            </div>
          </div>

          {/* 이전 상담 내역 */}
          <div className={`aica-medical-history-box ${isRecording ? 'disabled' : ''}`}>
            
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={chatBubbleIcon}
                  alt="이전상담내역"
                  className="icon-mark"
                />
              </div>
              <h3>
                {/*이전 상담 내역*/}
                {getDisplayText('medicalHistory')}
              </h3>
            </div>
           
            {/* 진료 내역 내용 */}
            <div className="aica-history-content">
              <div className="aica-history-list">
                {medicalHistory.map((history, index) => (
                  <div 
                    key={index} 
                    className={`aica-history-item ${selectedHistory?.seqNo === history.seqNo ? 'selected' : ''}`}
                    onClick={() => handleHistorySelect(history)}
                  >
                    <div className="aica-history-date">
                      {common.formatDateTimeDisplay(history.startDt)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>



        {/* --------- 오른쪽 섹션 - 상담 영역 --------- */}
        <div className="right-section">
          
          {/* 상담 영역 */}
          <div className="aica-chat-content-box">
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={clinicalNotesIcon}
                  alt="상담"
                  className="icon-mark"
                />
              </div>
              <h3>
                {/*상담*/}
                {getDisplayText('consultation')}
              </h3>
            </div>

            <div className="chat-buttons">
                <button 
                  className={`chat-button ${isRecording ? '' : 'active'}`}
                  onClick={startRecording}
                  disabled={isRecording || isLoading}>
                    상담시작
                </button>

                <button 
                  className={`chat-button ${isRecording ? 'active' : ''}`}
                  onClick={stopRecording}
                  disabled={!isRecording || isLoading}>
                    상담종료
                </button>
            </div>

            <div className="aica-chat-content">
              <textarea 
                className="aica-chat-textarea"
                placeholder="" 
                value={transcriptionText}
                readOnly
                />
            </div>
          </div>

          {/* 상담 내역 요약 */}
          <div className="chat-summary-box">
            <div className="aica-box-header">
              <div className="icon-circle">
                <img 
                  src={clinicalNotesIcon}
                  alt=""
                  className="icon-mark"
                />
              </div>
              <h3>
                {/*상담 내역 요약*/}
                {getDisplayText('summary')}
              </h3>
            </div>

            <div className="summary-content">
              {isLoading ? (
                <div className="loading-spinner">
                  <span>요약 생성 중...</span>
                  {/* 로딩 스피너 컴포넌트 추가 */}
                </div>
              ) : (
                <textarea
                  className="summary-textarea"
                  placeholder=""
                  value={summaryText}
                  readOnly 
                />
              )}
            </div>
          </div>

        </div>
      </div>



      
      
    </div>
  );
};

export default AicaFrame;
////////////////////////////////////////////////////////////////////////////////////


// 대용량 버퍼를 안전하게 Base64로 인코딩하는 함수 추가
const encodeAudioBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  const len = bytes.length;
  let base64 = '';
  
  // 청크 단위로 처리하여 콜 스택 초과 방지
  const chunkSize = 1024; // 적절한 청크 크기 설정
  
  for (let i = 0; i < len; i += chunkSize) {
    const chunk = bytes.slice(i, Math.min(i + chunkSize, len));
    base64 += String.fromCharCode.apply(null, chunk);
  }
  
  return btoa(base64);
};