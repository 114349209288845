import React, { useState, useEffect } from 'react';
import prod from '../config/prod';


const SearchBox = ({ user, onPatientSelect, showSearchPopup, setShowSearchPopup }) => {

  /* ----------------- 상태 관리 ----------------- */
  const [searchTerm, setSearchTerm] = useState(''); 
  //const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  //const debouncedValue = useDebounce(searchTerm, 2000);

  const [isSearching, setIsSearching] = useState(false); // 검색 중 상태
  const [isComposing, setIsComposing] = useState(false);  // IME 조합 상태 추가




  /* ----------------- 이벤트 함수 ----------------- */
  // 환자 검색어 입력 처리 함수
  const handleSearchChange = async (e) => {
    const term = e.target.value; // 검색어
    setSearchTerm(term);
    console.log('입력값:', term, 'isComposing:', isComposing);

    if (!term) {
        setShowSearchPopup(false);
        setSearchResults([]);
        setIsSearching(false);
        return;
    }

    if (term.length >= 2) {
        setIsSearching(true);
        setShowSearchPopup(true);
        await searchPatients(term);
        setIsSearching(false);
    }
  };

  // 환자 검색 함수
  const searchPatients = async (term) => {
    if (!term || term.length < 2) {
        setSearchResults([]);
        return;
    }

    try {
      const response = await fetch(`${prod.SERVER}/api/aica-wss/searchPatient`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userNo: user?.userNo,
          searchTerm: term
        })
      });

      if (!response.ok) {
        throw new Error('검색 실패');
      }

      // 검색 결과 처리
      const data = await response.json();
      setSearchResults(data);
    } catch (error) {
      console.error('환자 검색 실패:', error);
      setSearchResults([]);
    }
  };

  // 환자 선택 이벤트 처리
  const handlePatientClick = (patient) => {
    if (isSearching) return;  // 검색 중이면 클릭 무시

    console.log('[SearchBox.jsx] 환자 선택 이벤트 실행 ::: ', patient);
    
    onPatientSelect(patient);

    setTimeout(() => {
        setShowSearchPopup(false);
        setSearchTerm('');
        setSearchResults([]);
    }, 100);
  };



  /* ----------------- useEffect ----------------- */
  
  /*
  useEffect(() => {
    console.log('[SearchBox.jsx] 환자 검색 useEffect 실행');

    // 검색 실행 Effect
    if (debouncedValue && debouncedValue.length >= 2) {
      searchPatients(searchTerm);
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);
  */



  /* ----------------- 화면 렌더링 ----------------- */
  return (
    <div className={`search-box ${showSearchPopup ? 'popup-open' : ''}`}>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="환자 검색"
        className="search-input"
        onCompositionStart={() => {
            setIsComposing(true);
            console.log('onComposition START');
        }}
        onCompositionEnd={() => {
            setIsComposing(false);
            console.log('onComposition END');
        }}
        /*style={{
          WebkitImeMode: 'inactive',  // Safari, Chrome
          imeMode: 'inactive',        // IE, Firefox
          MozImeMode: 'inactive',     // Firefox
          msImeMode: 'inactive'       // IE
        }}*/
      />

    {showSearchPopup && searchResults.length > 0 && (
    <div className="search-popup">
        {searchResults.map((patient, index) => (
        <div 
            key={index}
            className="search-result-item"
            onClick={() => handlePatientClick(patient)}
            style={{ cursor: 'pointer', width: '100%', padding: '8px' }}
        >
            <span className="patient-name">{patient.patientName}</span>
        </div>
        ))}
    </div>
    )}
      
      {/*
      {showSearchPopup && searchResults.length > 0 && (
        <div className="search-popup">
          {searchResults.map((patient, index) => (
            <div 
              key={index}
              className="search-result-item"
              onClick={() => handlePatientClick(patient)}
            >
                <span>{patient.patientName}</span>
            </div>
          ))}
        </div>
      )}
      */}

    </div>
  );
};

export default SearchBox;