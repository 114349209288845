// 세션 관리 상수
const SESSION_DURATION = 24 * 60 * 60 * 1000; // 24시간


// 세션 인증 체크
export const checkAuth = () => {
  const user = sessionStorage.getItem('user');
  if (!user) return null;
  
  try {
    const userData = JSON.parse(user); // 세션 저장소에 저장된 유저 정보 파싱
    const now = new Date().getTime(); // 현재 시간

    // 세션 만료 체크
    if (!userData.expiresAt || now > userData.expiresAt) {
      sessionStorage.removeItem('user');
      console.log("[auth.js] 세션 만료 ::: ", userData);
      return null;
    }

    // 필수 데이터 존재 체크
    if (!userData.userId || !userData.userNo || !userData.url) {
      sessionStorage.removeItem('user');
      console.log("[auth.js] 유효하지 않은 사용자 데이터 ::: ", userData);
      return null;
    }

    return userData;
  } catch (error) {
    console.error('[auth.js] 인증 체크 실패:', error);
    sessionStorage.removeItem('user');
    return null;
  }
};


// 사용자 인증 정보 저장
export const setUserAuth = (userData) => {
  if (!userData || !userData.userId || !userData.userNo) {
    console.error('[auth.js] 유효하지 않은 사용자 데이터');
    return null;
  }

  try {
    const userInfo = {
      ...userData,
      expiresAt: new Date().getTime() + SESSION_DURATION
    };

    // 세션 저장소에 사용자 정보 저장
    sessionStorage.setItem('user', JSON.stringify(userInfo));

    return userInfo;
  } catch (error) {
    console.error('[auth.js] 사용자 정보 저장 실패:', error);
    return null;
  }
};


// 사용자 로그아웃
export const logout = () => {
  try {
    sessionStorage.removeItem('user');
    return true;
  } catch (error) {
    console.error('[auth.js] 로그아웃 실패:', error);
    return false;
  }
};